
import React,  { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import { Empty, notification } from 'antd';

import { coupanCodeModalAction, couponListAction, coupanViewModalAction } from '../../store/offer';
import { couponStatusChange, couponDelete } from '../../services/offerService';
import { getPreListAction } from '../../store/ui'
import { MiniLoader, MainLoader } from '../reuse/SVG';
import { dateFormat, programTypes } from '../../helper';
import ConfirmModal from '../modals/ConfirmModal';


const Coupan = props => {
    const [miniLoader, setMiniLoader] = useState('');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState('');
    const [confirmModal, setConfirmModal] = useState(false);
    const [currentId, setCurrentId] = useState('');

    const showCouponList = () => {
        let params = `?limit=${10}&page=${page - 1}&search=${search}`;
        props.couponListAction(params);
    }



    const changeStatus = couponCode => {
        setMiniLoader({[couponCode._id]: true});

        let params = {
            couponId: couponCode._id,
            status: couponCode.status == 0 ? '1' : '0' 
        }

        couponStatusChange(params).then(status => {
            setMiniLoader('');
            showCouponList();
            notification.open({
                message: 'Coupon Status',
                description: `Coupon status change successfully to  ${couponCode.status == 0 ? 'Active' : 'Inactive'}`
            });
        }); 
    }


    const handleConfirmModal = currentId => {
        setCurrentId(currentId);
        setConfirmModal(!confirmModal);
    }


    const handleDelete = () => {
        let params = { couponId: currentId }
        couponDelete(params).then(status => {
            setCurrentId('');
            setConfirmModal(false);
            showCouponList();
            
        }); 
    }



    




    useEffect(() => {
        showCouponList();
    }, [page])


    useEffect(() => {
        const _setTimeout = setTimeout(() => {
            showCouponList();
        }, 1000)
    
        return () => clearTimeout(_setTimeout)
    }, [search])

    return (
        <Fragment>
            <div className="search_wraper">
                {props.loader ? <div className="loader_wrap"><MainLoader /> </div> : ''}
                <i className="icon-search" />
                <input
                    type="text"
                    onChange={e => setSearch(e.target.value)}
                    className="form-control"
                    placeholder="Search by coupon name.." />
            </div>


    <div className="child_el">
        <div className="btn_wrapper">
            <button onClick={props.coupanCodeModalAction} className="btn btn-primary">Add Coupon Code</button>
        </div>
        <div className="table_responsive_wrap mt-4 text-center">
            
            <div className="table-responsive">
            
                <table className="table table-bordered table-striped no_t_texts">

                    <thead>
                        <tr>
                            <th><span className="t_text">S.No</span></th>
                            <th><span className="t_text">Action</span></th>
                            <th><span className="t_text">Camp Name</span></th>
                            <th><span className="t_text">Program Name</span></th>
                            <th><span className="t_text">Coupon Name</span></th>
                            <th><span className="t_text">Coupon Code</span></th>
                            <th><span className="t_text">Program Type</span></th>
                            <th><span className="t_text">Category</span></th>
                            <th><span className="t_text">Sub Category</span></th>
                            <th><span className="t_text">Type</span></th>
                            <th><span className="t_text">Value</span></th>
                            <th><span className="t_text">Start Date</span></th>
                            <th><span className="t_text">End Date</span></th>
                            <th><span className="t_text">Possible Usage</span></th>
                            <th><span className="t_text">Number Redeemed</span></th>
                            <th><span className="t_text">Private Coupon Code</span></th>
                            <th><span className="t_text">Refund</span></th>
                            <th><span className="t_text">User Code</span></th>
                            <th><span className="t_text">User Name</span></th>
                        </tr>
                    </thead>

                    <tbody>
                        {props.couponCodeList.map((couponCode, i) => (
                        <tr key={couponCode._id}>
                            <td><span className="t_text">{((i + 1) + ((page - 1) * limit))}</span></td>
                            <td>
                                <span className="t_text">
                                    <i className="icon-eye" onClick={() => props.coupanViewModalAction(couponCode)} />
                                    <i className="icon-delete" onClick={() => handleConfirmModal(couponCode._id)} />
                                    <div className="cm_swich_wrap">
                                        {miniLoader[couponCode._id] ? <div className="mini_loader"><MiniLoader /></div> : <React.Fragment><input type="checkbox" checked={couponCode.status} onChange={() => changeStatus(couponCode)} /><span /></React.Fragment>}
                                    </div>
                                </span>
                            </td>
                            <td><span className="t_text">{couponCode.camps[0]}</span></td>
                            <td><span className="t_text text-wrap" style={{minWidth: '300px'}}><p className="last_dot">{couponCode.programname.length ? couponCode.programname.map(item => <Fragment key={item}>{item}<span>,</span> </Fragment>) : ''}</p></span></td>
                            <td><span className="t_text">{couponCode.couponName}</span></td>
                            <td><span className="t_text">{couponCode.couponCode}</span></td>
                            <td><span className="t_text">{programTypes[Number(couponCode.programType)].label}</span></td>
                            <td><span className="t_text">{couponCode.category}</span></td>
                            {/* <td><span className="t_input last_dot">{couponCode.subcategory && couponCode.subcategory.length ? couponCode.subcategory.map(i => <Fragment key={i}>{i ? i : 'None'}<span>,</span> </Fragment>) : 'None'}</span></td>  */}
                            <td><span className="t_input last_dot">{couponCode.subCategoryName}</span></td> 
                            <td><span className="t_text">{couponCode.type ? 'Amount' : 'Percentage'}</span></td>
                            <td><span className="t_text">{couponCode.type ? '$' : ''}{couponCode.value}{couponCode.type ? '' : '%'}</span></td>
                            <td><span className="t_text">{dateFormat(couponCode.startDate)}</span></td>
                            <td><span className="t_text">{dateFormat(couponCode.endDate)}</span></td>
                            <td><span className="t_text">{couponCode.noOfTimesUsed}</span></td>
                            <td><span className="t_text">{couponCode.couponUsedByUser}</span></td>
                            <td>
                                <span className="t_text">
                                    <div className="check_wrapper">
                                        <input type="checkbox" checked={couponCode.isPrivate} /><span />
                                    </div>
                                </span>
                            </td>
                            <td>
                                <span className="t_text">
                                    <div className="check_wrapper">
                                        <input type="checkbox" checked={couponCode.refund} /><span />
                                    </div>
                                </span>
                            </td>
                            <td><span className="t_text">{couponCode.userCode}</span></td>
                            <td><span className="t_text">{couponCode.userName ? couponCode.userName : '---'}</span></td>
                        </tr>
                        ))
                    }
                        
                    
                    </tbody>
                </table>

                
            </div>
            {
                props.couponCodeList.length ? '' : <div className="mt-4 text-center cm_empty"><Empty description="Data Not Found" /></div>
            }
        </div>

    </div>

    {
        props.couponCodeList.length ? <div className="mt-4">
            <Pagination
                activePage={page}
                itemsCountPerPage={limit}
                totalItemsCount={props.couponCount}
                pageRangeDisplayed={3}
                onChange={page => setPage(page)}/>
        </div> : ''
    }

    

            <ConfirmModal
                show={confirmModal}
                hideModal={() => setConfirmModal(false)}
                msg="Are you sure you want to delete?"
                action={handleDelete} />


</Fragment>
    )
            }
/*************************************************************
                        Store Connect
*************************************************************/

const mapStateToProps = state => {
    let { couponCodeList, couponCount } = state.offer;
    return {
        couponCodeList,
        couponCount
    };
}
  
const mapDispatchToProps = dispatch => ({
    coupanCodeModalAction: () => dispatch(coupanCodeModalAction()),
    coupanViewModalAction: params => dispatch(coupanViewModalAction(params)),
    couponListAction: params => dispatch(couponListAction(params)),
    getPreListAction : () => dispatch(getPreListAction())


});

export default connect(mapStateToProps, mapDispatchToProps)(Coupan);
