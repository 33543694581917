import React, { useReducer, Fragment, useState, useEffect } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import Select from 'react-select';
import { DatePicker, InputNumber } from 'antd';

import {
  coupanCodeModalAction,
  addCouponAction,
  coupanViewModalAction,
  couponListAction
} from '../../store/offer';
import { dateFormat, programTypes, removeTime } from '../../helper';
import { setSubCategories } from '../../store/ui';
import moment from 'moment';

const { RangePicker } = DatePicker;


const CoupanCodeModal = props => {
  const [miniLoader, setMiniLoader] = useState(false);
  const [filterByCampsProgram, setFilterByCampsProgram] = useState([]);
  const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      couponName: '',
      couponCode: '',
      categoryId: null,
      subcategoryId: null,
      userCode: 0,
      programType: '',
      campId: [],
      programId: [],
      type: 0,
      value: 0,
      startDate: '',
      endDate: '',
      isPrivate: 1,
      noOfTimesUsed: 0,
      refund: 0
    }
  );

  const [error, setError] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      couponName: '',
      couponCode: '',
      campId: '',
      programId: '',
      type: '',
      value: '',
      startDate: '',
      endDate: '',
      noOfTimesUsed: '',
    }
  );


  const closeModal = () => {
    setUserInput(
      {
        couponName: '',
        couponCode: '',
        categoryId: null,
        subcategoryId: null,
        userCode: 0,
        programType: '',
        campId: [],
        programId: [],
        type: 0,
        value: 0,
        startDate: '',
        endDate: '',
        noOfTimesUsed: 0,
        isPrivate: 0
      }
    )

    setError(
      {
        couponName: '',
        couponCode: '',
        campId: '',
        programId: '',
        type: '',
        value: '',
        startDate: '',
        endDate: '',
        noOfTimesUsed: '',
      }
    )

    props.coupanCodeModalAction();
  }



  const handleChange = e => {
    setUserInput({[e.target.name]: e.target.value});
    setError({[e.target.name]: ''});
  }



  const handleChangeVal = (e, name) => {
    setUserInput({[name]: e});
    setError({[name]: ''});
  }


  const handleSelect = (e, name) => {
    if(name == 'categoryId'){
      const subCatArr = props.preList.category.filter(el => el._id == e.value)[0].subCategory;
        props.setSubCategories(subCatArr);
    }
    setUserInput({[name]: e.value});
    setError({[name]: ''});
  }


  const handleMaltiSelectChange = (e, name) => {
    setUserInput({[name]: e && e.map(i => i.value)});
    if(name == 'campId'){
      if(e){
        let temp = e.map(item => props.preList.program.filter(el => el.campId == item.value));
        setFilterByCampsProgram([].concat(...temp));
      }else{
        setFilterByCampsProgram([]);
      }
    }
    setError({[name]: ''});
  }



  const handleDateChange2 = e => {
    setUserInput({
      startDate: removeTime(e[0]._d),
      endDate: removeTime(e[1]._d)
    });
    setError({startDate: '', endDate: ''});
  }


  const handleChecboxChange = (val, name) => {
    setUserInput({[name]: val == 0 ? 1 : 0});
  }


  const handleValidate = () => {
    let validate = true;
    let {couponName, couponCode, value, startDate, endDate, noOfTimesUsed} = userInput;

    if(couponName == ''){
        setError({couponName: 'Coupon name is required'});
        validate = false
    }

    if(couponCode == ''){
        setError({couponCode: 'Coupon code is required'});
        validate = false
    }

    if(noOfTimesUsed == 0){
        setError({noOfTimesUsed: 'Possible Usage is required'});
        validate = false
    }

    if(value == ''){
        setError({value: 'value is required'});
        validate = false
    }

    if(startDate == ''){
        setError({startDate: 'Start date is required'});
        validate = false
    }

    if(endDate == ''){
        setError({endDate: 'End date is required'});
        validate = false
    }


    return validate;
}


  const handleSubmit = e => {
    e.preventDefault();
    
    if(handleValidate()){
      setMiniLoader(true);
      let { couponName, couponCode, categoryId, subcategoryId, userCode, programType, campId, programId, type, value, startDate, endDate, noOfTimesUsed, refund, isPrivate } = userInput;
      
      let params = {
        couponName,
        couponCode,
        categoryId,
        subcategoryId,
        userCode,
        programType,
        campId: JSON.stringify(campId),
        programId: JSON.stringify(programId),
        type,
        value,
        startDate: moment(startDate).format('MM-DD-YYYY'),
        endDate: moment(endDate).format('MM-DD-YYYY'),
        noOfTimesUsed,
        refund,
        isPrivate
      }

      props.addCouponAction(params).then(res => {
        props.couponListAction('');
        closeModal();
        setMiniLoader(false);
      })
    }

  }


  useEffect(() => {
    return () => {
      props.setSubCategories([]);
    }
  }, [])

  return (
    <Modal
      size="lg"
      show={props.toggleCoupanCodeModal}
      animation={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>{props.isCouponView ? 'View' : 'Add'} Coupon Code</Modal.Title>
        <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit} className="input_space_form">




          <div className="form-group">
            <label>Coupon Name*</label>
            {props.isCouponView ? <p className="inputs_view">{props.viewCoupon.couponName}</p> :
            <Fragment>
              <input
                type="text"
                placeholder="Coupon Name"
                className={`form-control ${error.couponName ? 'is-invalid' : ''}`}
                name="couponName"
                value={userInput.couponName}
                onChange={handleChange}
              />
              {error.couponName ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.couponName}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
            </Fragment>}
          </div>




          <div className="form-group">
            <label>Coupon Code*</label>
            {props.isCouponView ? <p className="inputs_view">{props.viewCoupon.couponCode}</p> :
            <Fragment>
              <input
                type="text"
                placeholder="Coupon Code"
                className={`form-control ${error.couponCode ? 'is-invalid' : ''}`}
                name="couponCode"
                value={userInput.couponCode}
                onChange={handleChange}
              />
              {error.couponCode ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.couponCode}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
            </Fragment>} 
          </div>




          <div className="row">
            <div className="col-md-6 form-group">
              <label>Coupon type</label>
              {props.isCouponView ? <p className="inputs_view">{props.viewCoupon.type ? 'Amount' : 'Percentage'}</p> :
              <Fragment>
                <Select
                  placeholder="Select a type"
                  onChange={e => handleSelect(e, 'type')}
                  classNamePrefix="cm_select"
                  className="form-control"
                  options={[
                      {value: 1, label: 'Amount'},
                      {value: 0, label: 'Percentage'},
                  ]}/>
              </Fragment>} 
            </div>



            <div className="col-md-6 form-group">
              <label>Value*</label>
              {props.isCouponView ? <p className="inputs_view">{props.viewCoupon.value}</p> :
              <Fragment>
                <InputNumber
                  onChange={e => handleChangeVal(e, 'value')}
                  //formatter={value => userInput.type ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : `${value}%`}
                  defaultValue={userInput.value}
                  placeholder="Value"
                  min={0}
                  max={userInput.type == 1 ? Number.MAX_VALUE : 100}
                  className={`form-control ${error.value ? 'is-invalid' : ''}`} />
                {error.value ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.value}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
              </Fragment>}
            </div>
          </div>



          <div className="form-group">
            <label>Start date - End date*</label>
            {props.isCouponView ? <p className="inputs_view">From {dateFormat(props.viewCoupon.startDate)} - to {dateFormat(props.viewCoupon.endDate)}</p> :
            <Fragment>
              <RangePicker
                disabledDate={d => d.isSameOrBefore(dateFormat(new Date().getTime()))}
                className={`form-control ${error.endDate ? 'is-invalid' : ''}`}
                onChange={handleDateChange2}
              />
              {error.endDate ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.endDate}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
            </Fragment>}
          </div>




          <div className="form-group">
            <label>Camp Code(s)</label>
                {/* {props.isCouponView ? <p className="inputs_view">{props.viewCoupon.camps.map(item => <span key={item}>{item} </span>)}</p> :   */}
                {props.isCouponView ? <p className="inputs_view">{props.viewCoupon?.camps?.join(', ')}</p> :  
            <Select
              isMulti
              onChange={e => handleMaltiSelectChange(e, 'campId')}
              classNamePrefix="cm_select"
              className="form-control"
              options={props.preList.camp.map(item => ({value: item._id, label: item.campName}))}/>}
          </div>





            <div className="form-group">
              <label>Program Code(s)</label> 
              {/* {props.isCouponView ? <p className="inputs_view">{props.viewCoupon.programname.map(item => <span key={item}>{item} </span>)}</p> :   */}
              {props.isCouponView ? <p className="inputs_view">{props.viewCoupon?.programname?.join(', ')}</p> :  
              <Select
                isMulti
                classNamePrefix="cm_select"
                className="form-control"
                onChange={e => handleMaltiSelectChange(e, 'programId')}
                //options={props.preList.program.map(item => ({value: item._id, label: item.programName}))} />}
                options={filterByCampsProgram.map(item => ({value: item._id, label: item.programName}))} />}
            </div>




          <div className="row">

            <div className="col-md-6 form-group">
              <label>Category</label>
              {props.isCouponView ?<p className="inputs_view">{props.viewCoupon.category ? props.viewCoupon.category : 'N/A'}</p> :
              <Select
                onChange={e => handleSelect(e, 'categoryId')}
                options={props.preList.category.map(item => ({value: item._id, label: item.name}))} />}
            </div>




            <div className="col-md-6 form-group">
              <label>Sub Category</label>
                {props.isCouponView ? <p className="inputs_view">{props.viewCoupon?.subCategoryName} </p> :
              <Select
                onChange={e => handleSelect(e, 'subcategoryId')}
                options={props.subcategories.map(item => ({value: item._id, label: item.name}))} />}
            </div>

            
          </div>



          <div className="form-group">
            <label>Program Type</label>
            {props.isCouponView ? <p className="inputs_view">
              {props.viewCoupon.programType ? programTypes[Number(props.viewCoupon.programType)].label : 'N/A'}
              </p> :  
             
            <Select
              placeholder="Program type"
              onChange={e => handleSelect(e, 'programType')}
              classNamePrefix="cm_select"
              value={programTypes.map(el => el.value == userInput.programType && el)}
              className="form-control"
              options={programTypes}
            />}
          </div>



          
          <div className="row">

            <div className="form-group col-md-6">
              <label>User Code</label>
              {props.isCouponView ? <p className="inputs_view">{props.viewCoupon.userCode ? props.viewCoupon.userCode : 'N/A'}</p> :  
              <input
                type="text"
                placeholder="User Code"
                className="form-control"
                name="userCode"
                onChange={handleChange} />}
            </div>




            <div className="form-group col-md-6">
              <label>Possible Usage*</label>
              {props.isCouponView ? <p className="inputs_view">{props.viewCoupon.noOfTimesUsed}</p> :
              <Fragment>
                <InputNumber
                  onChange={e => handleChangeVal(e, 'noOfTimesUsed')}
                  defaultValue={userInput.noOfTimesUsed }
                  placeholder="Value"
                  min={0}
                  className={`form-control ${error.noOfTimesUsed  ? 'is-invalid' : ''}`} />
                  {error.noOfTimesUsed ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.noOfTimesUsed }</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
              </Fragment>}
            </div>


          </div>


          


          <div className="row">

            <div className="col-md-6 form-group m-0">
              <p className={props.isCouponView ? 'inputs_view' : ''}>Private 
              {props.isCouponView ? <div className="check_wrapper ml-3"><input type="checkbox" checked={props.viewCoupon.isPrivate} /><span /></div> :
              
                <div className="check_wrapper ml-3 mt-2">
                  <input
                    name="isPrivate"
                    checked={userInput.isPrivate}
                    type="checkbox"
                    onClick={() => handleChecboxChange(userInput.isPrivate, 'isPrivate')} />
                  <span />
                </div>}  

                </p>
            </div>




            <div className="col-md-6 form-group m-0">
            <p className={props.isCouponView ? 'inputs_view' : ''}>Refund
              {props.isCouponView ? <div className="check_wrapper ml-3"><input type="checkbox" checked={props.viewCoupon.refund} /><span /></div> :
              
                <div className="check_wrapper ml-3 mt-2">
                  <input
                    name="refund"
                    checked={userInput.refund}
                    type="checkbox"
                    onClick={() => handleChecboxChange(userInput.refund, 'refund')}/>
                  <span />
                </div>}
              </p>
            </div>

            
          </div>


          <div className="d-flex justify-content-center mt-4 pb-4">
            <button onClick={closeModal} className="btn btn-light pl-5 pr-5">Cancel</button>
            {props.isCouponView ? '' : <button disabled={miniLoader} type="submit" className="btn btn-primary ml-4 pl-5 pr-5">Submit</button>}
           
          </div>
        </form>
      </Modal.Body>
    </Modal>


  )
}



const mapStateToProps = state => {
  let { toggleCoupanCodeModal, isCouponView, viewCoupon } = state.offer;
  let { preList, subcategories } = state.ui;
  
  return {
    toggleCoupanCodeModal,
    preList,
    isCouponView,
    viewCoupon,
    subcategories
  };
}

const mapDispatchToProps = dispatch => ({
  coupanCodeModalAction: () => dispatch(coupanCodeModalAction()),
  addCouponAction: params => dispatch(addCouponAction(params)),
  coupanViewModalAction: params => dispatch(coupanViewModalAction(params)),
  couponListAction: params => dispatch(couponListAction(params)),
  setSubCategories: params => dispatch(setSubCategories(params)),

});
 
export default connect(mapStateToProps, mapDispatchToProps)(CoupanCodeModal);